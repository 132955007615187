import { TFunction } from 'i18next'
import { z } from 'zod'

const schemaTimeMethodCalendar = (t: TFunction) => {
  return z.object({
    date: z.any().optional(),
    startTime: z
      .object({
        value: z.string(),
        supportingObj: z
          .object({
            name: z.string().optional()
          })
          .optional()
      })
      .nullish()
      .optional(),
    endTime: z
      .object({
        value: z.string(),
        supportingObj: z
          .object({
            name: z.string().optional()
          })
          .optional()
      })
      .nullish()
      .optional(),
    attendees: z
      .array(
        z.object({
          value: z.string(),
          label: z.string().optional(),
          avatar: z.any(),
          avatarVariants: z.any(),
          availableForSchedule: z.boolean().optional(),
          supportingObj: z.object({
            name: z.string(),
            description: z.string().optional(),
            defaultColour: z.string().optional()
          })
        })
      )
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .nullish()
      .refine((obj) => (obj || []).length > 0, {
        message: `${t('form:requiredField')}`
      }),
    duration: z
      .object({
        value: z.string(),
        supportingObj: z
          .object({
            name: z.string()
          })
          .optional()
      })
      .nullish()
      .optional(),
    timezone: z
      .object({
        value: z.string(),
        label: z.string(),
        supportingObj: z.object({
          name: z.string()
        })
      })
      .nullish()
  })
}

export default schemaTimeMethodCalendar
