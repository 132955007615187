import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { ISelectOption } from '~/core/ui/Select'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { DATE_FORMAT_YYYYMMDD } from '~/core/utilities/format-date'
import useBoundStore from '~/lib/store'
import QueryAvailableMembers from '../graphql/query-available-members'
import { AvailableMember, FirstStepFormType } from '../types'
import AgencyQueryAvailableMembers from '../graphql/agency-query-available-members'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT } from '~/core/constants/enum'

const useCheckAvailableAttendeesHook = (defaultValue?: FirstStepFormType) => {
  const { clientGraphQL } = useContextGraphQL()
  const setToast = useBoundStore((state) => state.setToast)
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })

  const [availableAttendees, setAvailableAttendees] =
    useState<Array<Partial<AvailableMember>>>()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  useEffect(() => {
    if (!availableAttendees && (defaultValue?.attendees || []).length > 0) {
      setAvailableAttendees(
        (
          (defaultValue?.attendees || []) as Array<
            ISelectOption & { availableForSchedule: boolean }
          >
        ).map((attendee) => ({
          fullName: `${attendee?.supportingObj?.name}`,
          id: Number(attendee?.value),
          email: `${attendee?.supportingObj?.description}`,
          avatarVariants: attendee.avatarVariants,
          defaultColour: attendee.supportingObj?.defaultColour,
          availableForSchedule: attendee.availableForSchedule
        }))
      )
    }
  }, [defaultValue, availableAttendees])

  const fetchAvailableAttendees = useCallback(
    (
      currentValue: FirstStepFormType,
      newFormValue?: Partial<FirstStepFormType>
    ) => {
      if (
        newFormValue?.attendees?.length === 0 ||
        (!newFormValue?.attendees &&
          (!currentValue?.attendees || currentValue?.attendees?.length === 0))
      ) {
        return setAvailableAttendees([])
      }
      setIsFetching(true)

      const attendees = newFormValue?.attendees || currentValue?.attendees || []
      const date = newFormValue?.date || currentValue?.date
      const startTime = newFormValue?.startTime || currentValue?.startTime
      const endTime = newFormValue?.endTime || currentValue?.endTime
      const timezone = newFormValue?.timezone || currentValue?.timezone
      const params = {
        page: 1,
        limit: 25,
        ...(date
          ? {
              fromDatetime: `${format(date, DATE_FORMAT_YYYYMMDD)}T${
                startTime?.value
              }:00${timezone?.label}`,
              toDatetime: `${format(date, DATE_FORMAT_YYYYMMDD)}T${
                endTime?.value
              }:00${timezone?.label}`
            }
          : {}),
        timezone: currentValue?.timezone?.value,
        ...(attendees?.length > 0
          ? {
              attendeeIds: (attendees || []).map((attendee) =>
                Number(attendee.value)
              )
            }
          : {}),
        jobStageId: isCompanyKind
          ? Number(currentValue?.jobStageId?.value)
          : undefined
      }
      return clientGraphQL
        .query(
          isCompanyKind ? AgencyQueryAvailableMembers : QueryAvailableMembers,
          params
        )
        .toPromise()
        .then((result: IResponseContextResult<AvailableMember>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              setToast
            })
          }

          const { membersAvailableList } = result?.data
          const collection = membersAvailableList?.collection || []
          //   const metadata = membersAvailableList?.metadata || {}

          setAvailableAttendees(collection)
          setIsFetching(false)
        })
    },
    [clientGraphQL, isCompanyKind, setToast]
  )
  return {
    availableAttendees,
    isFetching,
    fetchAvailableAttendees
  }
}

export default useCheckAvailableAttendeesHook
