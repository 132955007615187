import { TFunction } from 'i18next'
import { z } from 'zod'
import { DATE_TIME_TYPE_VALUE } from '../utilities/enum.cva'
import { regexUrlValidation } from '~/lib/schema'

const schemaScheduleInterviewStep1 = (t: TFunction) => {
  return z
    .object({
      date: z.coerce.date({
        required_error: `${t('form:requiredField')}`
      }),
      startTime: z
        .object({
          value: z.string(),
          supportingObj: z
            .object({
              name: z.string().optional()
            })
            .optional()
        })
        .nullish()
        .refine((obj) => !!obj?.value, {
          message: `${t('form:requiredField')}`
        }),
      endTime: z
        .object({
          value: z.string(),
          supportingObj: z
            .object({
              name: z.string().optional()
            })
            .optional()
        })
        .nullish()
        .refine((obj) => !!obj?.value, {
          message: `${t('form:requiredField')}`
        }),
      eventType: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .nullish()
        .refine((obj) => !!obj?.value, {
          message: `${t('form:requiredField')}`
        }),
      timezone: z
        .object({
          value: z.string(),
          label: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .nullish()
        .refine((obj) => !!obj?.value, {
          message: `${t('form:requiredField')}`
        }),
      meetingUrl: z
        .string()
        .max(250, `${t('form:field_max_number_required', { number: 250 })}`)
        .optional(),
      locationId: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .nullish(),
      attendees: z
        .array(
          z.object({
            value: z.string(),
            label: z.string().optional(),
            availableForSchedule: z.boolean().optional(),
            avatar: z.any(),
            supportingObj: z.object({
              name: z.string(),
              description: z.string().optional(),
              defaultColour: z.string().optional()
            })
          })
        )
        .min(1, {
          message: `${t('form:requiredField')}`
        })
        .nullish()
        .refine((obj) => (obj || []).length > 0, {
          message: `${t('form:requiredField')}`
        }),
      jobIkitId: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .nullish(),
      jobStageId: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .nullish()
        .refine((obj) => !!obj?.value, {
          message: `${t('form:requiredField')}`
        }),
      remindFeedback: z.boolean().optional(),
      remindSchedule: z.boolean().optional(),
      method: z.string().optional(),
      timeSlots: z
        .array(
          z
            .object({
              fromDatetime: z.string(),
              toDatetime: z.string(),
              timezone: z.string()
            })
            .nullish()
        )
        .optional(),
      remindBeforeTime: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .optional(),
      remindAfterTime: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .optional()
    })
    .refine(
      (data) => {
        return (
          !data.meetingUrl ||
          (!!data.meetingUrl && regexUrlValidation.test(data.meetingUrl))
        )
      },
      {
        path: ['meetingUrl'],
        message: `${t('form:invalid_url')}`
      }
    )
    .refine(
      (data) => {
        return (
          data.method === DATE_TIME_TYPE_VALUE.specific ||
          (data.method === DATE_TIME_TYPE_VALUE.self_schedule &&
            (data.timeSlots || [])?.length > 0)
        )
      },
      {
        path: ['timeSlots'],
        message: `${t('form:please_select_at_least_one_slot')}`
      }
    )
}

export default schemaScheduleInterviewStep1
