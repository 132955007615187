import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { IPromiseSearchOption } from '~/core/ui/Select'
import QueryEmailTemplates from '../graphql/query-email-templates'
import QueryEmailTemplatesAttendees from '../graphql/query-email-templates-attendees'
import { IEmailResponseType } from '../types'
import { EMAIL_TEMPLATE_TAG_KIND } from '../utilities/enum'

export function useQueryEmailTemplateAttendees() {
  const { clientGraphQL } = useContextGraphQL()
  const promiseEmailTemplatesAttendees = () =>
    clientGraphQL
      .query(QueryEmailTemplatesAttendees, {})
      .toPromise()
      .then(
        (result: {
          error: object
          data: {
            defaultEventAttendeeEmailTemplate: {
              name: string
              body: string
              subject: string
            }
          }
        }) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { defaultEventAttendeeEmailTemplate } = result.data

          return {
            metadata: { totalCount: 1 },
            collection: [defaultEventAttendeeEmailTemplate]
          }
        }
      )

  const promiseEmailTemplates = (params = {} as IPromiseSearchOption) =>
    new Promise<IEmailResponseType>((resolve) => {
      return clientGraphQL
        .query(QueryEmailTemplates, {
          ...params,
          tag: EMAIL_TEMPLATE_TAG_KIND.eventInformation
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            return resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { emailTemplatesList } = result.data
          const collection = emailTemplatesList?.collection || []
          const metadata = emailTemplatesList?.metadata || {}
          const cloneData = collection.map((item) => {
            return {
              value: item.id,
              name: item.name,
              body: item.body,
              emailKind: item.emailKind,
              default: item.default,
              subject: item.subject,
              createdBy: item.createdBy,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  return { promiseEmailTemplatesAttendees, promiseEmailTemplates }
}
