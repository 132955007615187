import { useTranslation } from 'react-i18next'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { ISelectOption } from '~/core/ui/Select'
import QueryInterviewTimeRanges from '../graphql/query-interview-time-ranges'

const useInterviewTimeRangesHook = () => {
  const { t } = useTranslation()
  const { data: reminderBefore } = useQueryGraphQL({
    query: QueryInterviewTimeRanges,
    variables: {},
    shouldPause: false
  })

  const { data: reminderAfter } = useQueryGraphQL({
    query: QueryInterviewTimeRanges,
    variables: {
      rangeType: 'after'
    },
    shouldPause: false
  })

  const getSortedHash = (inputHash: { [key: string]: number }) => {
    let resultHash: { [key: string]: number } = {}
    let keys = Object.keys(inputHash)
    keys
      .sort(function (a, b) {
        return inputHash[a] - inputHash[b]
      })
      .forEach(function (k) {
        resultHash[k] = inputHash[k]
      })
    return resultHash
  }

  const mappingTimeRanges = (timeRanges?: {
    [key: string]: number
  }): ISelectOption[] => {
    return Object.keys(getSortedHash(timeRanges || {})).map((timeKey) => ({
      value: timeKey,
      supportingObj: {
        name: `${t(`interview:timeRange:${timeKey}`)}`
      }
    }))
  }

  return {
    timeBeforeRanges: mappingTimeRanges(
      reminderBefore?.interviewTimeRangeForSelecting || {}
    ),
    timeAfterRanges: mappingTimeRanges(
      reminderAfter?.interviewTimeRangeForSelecting || {}
    )
  }
}

export default useInterviewTimeRangesHook
