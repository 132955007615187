import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import { Dialog } from '~/core/ui/Dialog'
import { Divider } from '~/core/ui/Divider'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { useMoreLinkPopover } from '~/lib/features/calendar/hooks/use-get-more-link-popover'
import {
  FirstStepFormType,
  InterviewDetailType,
  InterviewFormStep2,
  InterviewParamsType,
  InterviewStep2EmailEditor
} from '~/lib/features/calendar/types'
import {
  formatStartEndDate,
  getTimeSelectOptions
} from '~/lib/features/calendar/utilities/helper-schedule-interview'
import { INTERVIEWS_TAB } from '~/lib/features/candidates/utilities/enum'
import { mappingScheduleInterview } from '~/lib/features/interviews/mapping/schedule-interview-mapping'
import useBoundStore from '~/lib/store'
import AddEmailModal from './AddEmailModal'
import DiscardUnchangeModal from './DiscardUnchangeModal'
import FirstStepForm from './FirstStepForm'
import SecondStepForm from './SecondStepForm'
import toast from 'react-hot-toast'
import { Toast } from '~/core/ui/Toast'

const ScheduleInterviewModal: FC<{
  reload?: () => Promise<void>
  open: boolean
  reopenInterviewModal: () => void
  onClose: () => void
  interviewInfo?: InterviewDetailType
  isEditInterview?: boolean
  onFinish: (data: InterviewParamsType) => Promise<boolean>
  candidateProfile: {
    id: number
    email?: Array<string> | string
  }
  callbackSubmitAddEmail?: (data: { email: string | string[] }) => void
}> = ({
  reload,
  open,
  onClose,
  isEditInterview = false,
  reopenInterviewModal,
  onFinish,
  interviewInfo,
  candidateProfile,
  callbackSubmitAddEmail
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const setToast = useBoundStore((state) => state.setToast)
  const [openUnchangeModal, setOpenUnchangeModal] = useState<boolean>(false)
  const [openEmailForm, setOpenEmailForm] = useState<boolean>(false)
  const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [interviewSubmitData, setInterviewSubmitData] = useState<
    FirstStepFormType & InterviewFormStep2
  >({} as FirstStepFormType & InterviewFormStep2)
  const { moreLinkPopover } = useMoreLinkPopover()

  const nextStep = useCallback(
    (data: FirstStepFormType) => {
      setInterviewSubmitData({ ...interviewSubmitData, ...data })
      setStep(step + 1)
    },
    [step, interviewSubmitData]
  )

  const onCloseInterview = useCallback(() => {
    setStep(1)
    onClose()
    setIsSubmittingForm(false)
  }, [onClose])

  const previousStep = useCallback(
    (data: InterviewStep2EmailEditor) => {
      const dataStep2 = {
        sendEmailToAttendees: data.sendEmailToAttendees,
        sendEmailToCandidate: data.sendEmailToCandidate,
        emailTemplate: {
          ...data.emailTemplate,
          value: String(data.emailTemplate?.value)
        },
        htmlBodyCandidate: data.htmlBodyCandidate,
        subjectCandidate: data.subjectCandidate,
        to: data.to,
        toAttendees: data.toAttendees,
        htmlBodyAttendees: data.htmlBodyAttendees,
        subjectAttendees: data.subjectAttendees,
        bccAttendees: data.bccAttendees,
        ccAttendees: data.ccAttendees
      }
      setStep(step - 1)
      setInterviewSubmitData({ ...interviewSubmitData, ...dataStep2 })
    },
    [step, interviewSubmitData]
  )

  const onScheduleInterviewBySpecificTime = useCallback<
    (data?: InterviewStep2EmailEditor) => Promise<void>
  >(
    (dataStep2) => {
      setIsSubmittingForm(true)
      let sendEmailToAttendees = dataStep2?.sendEmailToAttendees
      let sendEmailToCandidate = dataStep2?.sendEmailToCandidate
      const formatDataStep2: InterviewParamsType = {
        sendEmailToAttendees,
        sendEmailToCandidate,
        ...(!!dataStep2?.sendEmailToCandidate
          ? {
              subjectCandidate: dataStep2.subjectCandidate,
              htmlBodyCandidate: dataStep2.htmlBodyCandidate,
              emailTemplateId: Number(dataStep2.emailTemplate?.value)
            }
          : {}),
        ...(!!dataStep2?.sendEmailToAttendees
          ? {
              ccAttendees: (dataStep2?.ccAttendees || []).map((cc) => cc.value),
              bccAttendees: (dataStep2?.bccAttendees || []).map(
                (bcc) => bcc.value
              ),
              subjectAttendees: dataStep2.subjectAttendees,
              htmlBodyAttendees: dataStep2.htmlBodyAttendees
            }
          : {})
      }

      const { start, end } = formatStartEndDate({
        startTime: interviewSubmitData?.startTime?.value,
        endTime: interviewSubmitData?.endTime?.value,
        date: interviewSubmitData.date,
        timezone: interviewSubmitData?.timezone?.label,
        timeOptions: getTimeSelectOptions({})
      })

      const formatData: InterviewParamsType = {
        ...(interviewInfo?.id ? { id: Number(interviewInfo?.id) } : {}),
        ...(interviewInfo?.profile?.id
          ? { profileId: Number(interviewInfo?.profile?.id) }
          : {}),
        fromDatetime: start,
        toDatetime: end,

        timezone: interviewSubmitData.timezone?.value,
        eventType: interviewSubmitData.eventType?.value,
        eventTypeDescription:
          interviewSubmitData.eventType?.supportingObj?.name ||
          `${t('interview:schedule_modal:phoneInterview')}`,
        meetingUrl:
          interviewSubmitData.meetingUrl !== undefined &&
          interviewSubmitData.meetingUrl !== ''
            ? interviewSubmitData.meetingUrl
            : null,
        locationId: interviewSubmitData.locationId?.value
          ? Number(interviewSubmitData.locationId?.value)
          : null,
        ...((interviewSubmitData.attendees || [])?.length > 0
          ? {
              attendeeIds: interviewSubmitData.attendees?.map((item) =>
                Number(item.value)
              ),
              attendees: interviewSubmitData.attendees?.map((attendee) => ({
                id: Number(attendee.value),
                fullName: attendee?.supportingObj?.name,
                avatarVariants: attendee?.avatarVariants,
                roles: attendee?.label,
                email: attendee?.supportingObj?.description
              }))
            }
          : {}),
        remindFeedback: !!interviewSubmitData.remindFeedback,
        remindSchedule: !!interviewSubmitData?.remindSchedule,
        jobStageId: interviewSubmitData.jobStageId?.value
          ? Number(interviewSubmitData.jobStageId.value)
          : undefined,
        jobIkitId: interviewSubmitData.jobIkitId?.value
          ? Number(interviewSubmitData.jobIkitId.value)
          : undefined,
        remindBeforeTime: interviewSubmitData.remindBeforeTime?.value,
        remindAfterTime: interviewSubmitData?.remindAfterTime?.value,
        ...formatDataStep2
      }

      setInterviewSubmitData({
        ...interviewSubmitData,
        ...(dataStep2 as InterviewFormStep2)
      })
      return onFinish(formatData).then((error) => {
        if (error) {
          setIsSubmittingForm(false)

          toast.custom((to) => (
            <Toast
              open
              type="error"
              title={t('notification:interview:scheduleInterviewFailed')}
              onClose={() => toast.remove(to.id)}
            />
          ))
          return
        }

        toast.custom((to) => (
          <Toast
            open
            type="success"
            title={
              isEditInterview
                ? t('notification:interview:interviewUpdated')
                : t('notification:interview:interviewCreated')
            }
            description={
              sendEmailToCandidate
                ? `${t('notification:interview:aNotificationEmailHasBeenSent')}`
                : ''
            }
            actions={[
              {
                alt: 'details',
                label: `${t('button:details')}`,
                actionType: 'detail',
                callback: () => {
                  interviewInfo?.profile?.id &&
                    router.push(
                      `${pathConfiguration.candidates.detail(
                        interviewInfo?.profile?.id,
                        interviewInfo?.applicant?.id
                      )}?profile-tabs=${INTERVIEWS_TAB}`
                    )
                }
              }
            ]}
            onClose={() => toast.remove(to.id)}
          />
        ))

        reload && reload()
        setInterviewSubmitData({})
        onCloseInterview()
      })
    },
    [
      interviewSubmitData,
      interviewInfo?.id,
      interviewInfo?.profile?.id,
      interviewInfo?.applicant?.id,
      onFinish,
      setToast,
      isEditInterview,
      t,
      onCloseInterview,
      router
    ]
  )
  const onScheduleInterviewBySelfSchedule = useCallback<
    (data: FirstStepFormType) => Promise<void>
  >(
    (data) => {
      setIsSubmittingForm(true)
      const formatData: InterviewParamsType = {
        ...(interviewInfo?.id ? { id: Number(interviewInfo?.id) } : {}),
        ...(interviewInfo?.profile?.id
          ? { profileId: Number(interviewInfo?.profile?.id) }
          : {}),

        timezone: data.timezone?.value,
        eventType: data.eventType?.value,
        eventTypeDescription:
          data.eventType?.supportingObj?.name ||
          `${t('interview:schedule_modal:phoneInterview')}`,
        meetingUrl:
          data.meetingUrl !== undefined && data.meetingUrl !== ''
            ? data.meetingUrl
            : null,
        locationId: data.locationId?.value
          ? Number(data.locationId?.value)
          : null,
        ...((data.attendees || [])?.length > 0
          ? {
              attendeeIds: data.attendees?.map((item) => Number(item.value)),
              attendees: data.attendees?.map((attendee) => ({
                id: Number(attendee.value),
                fullName: attendee?.supportingObj?.name,
                avatarVariants: attendee?.avatarVariants,
                roles: attendee?.label,
                email: attendee?.supportingObj?.description
              }))
            }
          : {}),
        remindFeedback: !!data.remindFeedback,
        jobStageId: data.jobStageId?.value
          ? Number(data.jobStageId.value)
          : undefined,
        jobIkitId: data.jobIkitId?.value
          ? Number(data.jobIkitId.value)
          : undefined,
        timeSlots: data?.timeSlots
      }

      return onFinish(formatData).then((error) => {
        if (error) {
          setIsSubmittingForm(false)
          setToast({
            open: true,
            type: 'error',
            title: t('notification:interview:scheduleInterviewFailed')
          })
          return
        }

        setToast({
          open: true,
          type: 'success',
          title: isEditInterview
            ? t('notification:interview:interviewUpdated')
            : t('notification:interview:interviewCreated'),
          description: `${t(
            'notification:interview:aNotificationEmailHasBeenSent'
          )}`,
          duration: 2000,
          actions: [
            {
              alt: 'details',
              label: `${t('button:details')}`,
              actionType: 'detail',
              callback: () => {
                interviewInfo?.profile?.id &&
                  router.push(
                    `${pathConfiguration.candidates.detail(
                      interviewInfo?.profile?.id,
                      interviewInfo?.applicant?.id
                    )}?profile-tabs=${INTERVIEWS_TAB}`
                  )
              }
            }
          ]
        })
        setInterviewSubmitData({})
        onCloseInterview()
      })
    },
    [
      interviewInfo?.id,
      interviewInfo?.profile?.id,
      interviewInfo?.applicant?.id,
      t,
      onFinish,
      setToast,
      isEditInterview,
      onCloseInterview,
      router
    ]
  )

  const onConfirmCloseInterview = useCallback(() => {
    onCloseInterview()

    step === 1 && setInterviewSubmitData({})
    step === 2 && setOpenUnchangeModal(true)
  }, [onCloseInterview, step])

  const onCloseUnchangeModal = useCallback(() => {
    setOpenUnchangeModal(false)
  }, [])
  const onDiscardChange = useCallback(() => {
    setInterviewSubmitData({})
  }, [])

  const caseEditGetPickedTimeSlot = useMemo(() => {
    let filterValue = interviewInfo?.interviewTimeSlots?.filter((i) => i.picked)
    if (filterValue?.[0]) {
      if (interviewInfo?.fromDatetime && interviewInfo?.toDatetime) {
        return {
          ...filterValue?.[0],
          fromDatetime: interviewInfo?.fromDatetime,
          toDatetime: interviewInfo?.toDatetime
        }
      }
      return filterValue?.[0]
    }

    return undefined
  }, [interviewInfo])

  useEffect(() => {
    if (!!interviewInfo && Object.keys(interviewSubmitData).length === 0) {
      const formatData = mappingScheduleInterview(
        caseEditGetPickedTimeSlot
          ? {
              ...interviewInfo,
              fromDatetime: caseEditGetPickedTimeSlot?.fromDatetime,
              toDatetime: caseEditGetPickedTimeSlot?.toDatetime
            }
          : interviewInfo,
        t
      )
      setInterviewSubmitData(formatData)
    }
  }, [interviewInfo, interviewSubmitData])

  useEffect(() => {
    if (candidateProfile)
      setOpenEmailForm(
        !candidateProfile.email || (candidateProfile.email || [])?.length === 0
      )
  }, [candidateProfile])

  return (
    <div
      {...(!!moreLinkPopover
        ? { onMouseDown: (e) => e.stopPropagation() }
        : {})}>
      <Dialog
        open={
          open &&
          !!candidateProfile?.email &&
          (candidateProfile?.email || [])?.length > 0
        }
        size="md"
        onOpenChange={onConfirmCloseInterview}
        isDivider={false}
        isPreventAutoFocusDialog={true}
        label={
          isEditInterview
            ? `${t('interview:schedule_modal:edit_interview')}`
            : `${t('interview:schedule_modal:schedule')}`
        }
        headingClassName="tablet:pb-0">
        <div className="pb-4 tablet:pb-5">
          <div className="mt-1 flex w-full items-center justify-between">
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="User"
                className="flex-none text-gray-600"
              />
              <TypographyText className="line-clamp-1 text-sm text-gray-900">
                {interviewInfo?.profile?.fullName}
              </TypographyText>
            </div>
          </div>
          <div className="mt-1 flex items-center space-x-2">
            <IconWrapper
              size={16}
              name="Briefcase"
              className="flex-none text-gray-600"
            />
            <TypographyText className="text-sm text-gray-900">
              {interviewInfo?.applicant?.job?.title}
            </TypographyText>
          </div>
        </div>
        <Divider
          className={cn('-mx-4 tablet:-mx-6', step === 2 ? 'mb-4' : 'mb-2')}
        />
        {
          {
            1: (
              <FirstStepForm
                onClose={onConfirmCloseInterview}
                nextStep={nextStep}
                onFinish={onScheduleInterviewBySelfSchedule}
                interviewInfo={interviewInfo}
                defaultValue={interviewSubmitData}
                isEditInterview={isEditInterview}
                isSubmittingForm={isSubmittingForm}
              />
            ),
            2: (
              <SecondStepForm
                interviewSubmitData={interviewSubmitData}
                interviewInfo={interviewInfo}
                onPreviewStep={previousStep}
                onClose={onConfirmCloseInterview}
                onScheduleInterview={onScheduleInterviewBySpecificTime}
                reload={reload}
                isSubmittingForm={isSubmittingForm}
              />
            )
          }[step]
        }
      </Dialog>
      <DiscardUnchangeModal
        onClose={onCloseUnchangeModal}
        open={openUnchangeModal}
        keepScheduleCallback={reopenInterviewModal}
        onDiscardChange={onDiscardChange}
      />
      <AddEmailModal
        open={openEmailForm && open}
        onClose={() => setOpenEmailForm(false)}
        candidateProfileId={Number(candidateProfile?.id)}
        callbackSubmit={callbackSubmitAddEmail}
        callbackCancel={() => onClose()}
        reload={reload}
      />
    </div>
  )
}

export default ScheduleInterviewModal
